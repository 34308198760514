import { Button, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { green, grey, red, yellow } from "@mui/material/colors";
import axios from "axios";
import { BusinessPlanState } from "context/BusinessPlanningContext";
import { roundDecimalValueUptoTwo } from "utils/roundDecimalValue";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "context/GlobalContext";
import { getDatesofSeason } from "utils/getDatesofSeason";
import {
	calculateAsp,
	calculateDisByTotalMrpAndTotalNSV,
	calculateDiscount,
	calculateNSVByMRPDis,
	calculateRos,
	calculateSTByTotalPcsAndTotalQty,
	calculateSTByTotalPcsAndTottalQty,
	calculateTotalPcs,
	calculteTotalPcs,
} from "utils/calculateStoreData";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { rootProtectedPath, services } from "data/authData";

const borderLeft = `0px`;

const largeFont = 16;

const normalFont = 14;

const DefRenderCellClass = {
	borderBottom: "none",
	width: "100%",
	textAlign: "center",
	cursor: "pointer",
	minWidth: "100%",
	height: "100%",
	borderBottom: `2px solid ${grey[300]}`,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};
const DefRenderCell = ({ value, row, font }) => {
	return (
		<Box
			sx={{
				...DefRenderCellClass,
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				fontSize: font,
			}}>
			{value}
		</Box>
	);
};
const TableInLakhsDisplay = ({ value, row, font }) => {
	return (
		<Box
			sx={{
				...DefRenderCellClass,
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				fontSize: font,
			}}>
			<InLakhsDisplay number={value} sx={{ fontWeight: "normal" }} />
		</Box>
	);
};
const QtyWithContribution = ({ value, row, border, type, total_qty }) => {
	let borderToAdd = {};
	const contri = roundDecimalValueUptoTwo((total_qty ? value / total_qty : 0) * 100);
	if (border) borderToAdd = { ...border };
	if (row.DEPARTMENT === "TOTAL") borderToAdd = { ...borderToAdd, borderColor: grey[200], borderWidth: "0px" };
	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				borderBottom: `2px solid ${grey[300]}`,
				cursor: "pointer",
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				height: "100%",
				display: "flex",
				justifyContent: "space-around",
				alignItems: "center",
				...borderToAdd,
			}}>
			<Typography
				fontSize={type === "NEW" ? normalFont : largeFont}
				fontWeight={type === "NEW" ? "normal" : "bold"}>
				{value ? value : 0}
			</Typography>
			<Typography
				fontSize={type === "NEW" ? normalFont - 3 : largeFont - 3}
				fontWeight={type === "NEW" ? "normal" : "bold"}>
				<span
					style={{
						color: row.DEPARTMENT === "TOTAL" ? red[400] : type === "NEW" ? green[400] : red["A400"],
					}}>
					{contri}%
				</span>
			</Typography>
		</Box>
	);
};
const DiscountRenderCell = ({ row, type, value }) => {
	let borderToAdd = {};
	if (row.DEPARTMENT === "TOTAL") borderToAdd = { ...borderToAdd, borderColor: grey[200], borderWidth: "0px" };
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				textAlign: "center",
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontWeight: type === "NEW" ? "normal" : "bold",
				fontSize: type === "NEW" ? normalFont : largeFont,
				...borderToAdd,
				borderBottom: `2px solid ${grey[300]}`,
			}}>
			{value ? <>{roundDecimalValueUptoTwo(value)}</> : <>0</>}
			<span style={{ fontWeight: "normal", fontSize: "14px" }}>%</span>
		</Box>
	);
};

const SubCategoryPage = ({ division }) => {
	const { selectedBp, setSelectedBp, selectedStore, setSelectedStore, selectedCategory, setSelectedCategory } =
		BusinessPlanState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	useEffect(() => {
		if (selectedCategory && selectedCategory.categorySubCategories) {
			setLTLCount(selectedCategory.ltl_qty);
			setNewCount(selectedCategory.new_qty);
			setSubCategories(selectedCategory.categorySubCategories);
		}
	}, [selectedStore, division, selectedCategory]);
	const [newAllMrps, setNewAllMrps] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState();
	const [LTLCount, setLTLCount] = useState(0);
	const [newCount, setNewCount] = useState(0);
	const [modalOpen, setModalOpen] = useState(0);
	const navigate = useNavigate();

	const SubCatTableColumns = [
		{
			field: "sub_category",
			width: 130,
			headerAlign: "center",
			headerName: "Sub-Category",
			colSpan: (val, row ) => {
				if (row.DEPARTMENT === "TOTAL") {
					return 3;
				}
				return undefined;
			},
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.sub_category} row={row} font={normalFont} />;
			},
		},

		{
			field: "ltl_qty",
			headerName: "PRV QTY",
			width: 120,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <QtyWithContribution value={row.ltl_qty} row={row} type="OLD" total_qty={LTLCount} />;
			},
		},
		{
			field: "new_qty",
			headerName: "NEW QTY",
			width: 120,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <QtyWithContribution value={row.new_qty} row={row} type="NEW" total_qty={newCount} />;
			},
		},
		{
			field: "ltl_dis",
			headerName: "PRV DIS",
			headerAlign: "center",
			width: 80,
			renderCell: (params) => {
				return <DiscountRenderCell type="OLD" row={params.row} value={params.row.ltl_dis} />;
			},
		},
		{
			field: "new_dis",
			headerName: "NEW DIS",
			headerAlign: "center",
			width: 90,
			renderCell: (params) => {
				return <DiscountRenderCell type="NEW" value={params.row.new_dis} row={params.row} />;
			},
		},
		{
			field: "ltl_st",
			headerName: "PRV ST",
			headerAlign: "center",

			width: 60,
			renderCell: (params) => {
				return <DiscountRenderCell type="OLD" value={params.row.ltl_st} row={params.row} />;
			},
		},
		{
			field: "new_st",
			headerName: "NEW ST",
			width: 95,
			headerAlign: "center",

			renderCell: (params) => {
				return <DiscountRenderCell type="NEW" value={params.row.new_st} row={params.row} />;
			},
		},

		{
			field: "total_pcs",
			headerName: "TOTAL PCS",
			width: 85,
			headerAlign: "center",
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.total_pcs} row={row} border={{ borderLeft }} />;
			},
		},
		{
			field: "ltl_asp",
			headerName: "PRV ASP",
			width: 90,
			headerAlign: "center",
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.ltl_asp} row={row} />;
			},
		},
		{
			field: "new_asp",
			headerName: "NEW ASP",
			width: 95,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <DefRenderCell value={row.new_asp} row={row} />;
			},
		},
		{
			field: "ltl_nsv",
			headerName: "PRV NSV",
			width: 100,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <TableInLakhsDisplay value={row.ltl_nsv} row={row} />;
			},
		},
		{
			field: "new_nsv",
			headerName: "NEW NSV",
			width: 110,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <TableInLakhsDisplay value={row.new_nsv} row={row} />;
			},
		},
	];
	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handleSubCategorySelect = async (params, e) => {
		const row = params.row;
		setModalOpen(true);
		setSelectedSubCategory({ ...row, subCategoryMrps: [...row.subCategoryMrps] });
		console.log(row.subCategoryMrps);
		setNewAllMrps(row.subCategoryMrps ? [...row.subCategoryMrps] : []);

		const attributeValues = selectedCategory?.categorySubCategories?.reduce((acc, subCat) => {
			acc = [
				...new Set([
					...subCat.subCategoryMrps?.map((row) => {
						return row.attribute_value;
					}),
					...acc,
				]),
			];
			return acc;
		}, []);
		setAttributeOptions(attributeValues);
	};
	const modalStyle = {
		width: 900,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: "10px 15px",
		borderRadius: 2,
	};
	const mrpSSColumn = [
		{ field: "mrp", headerName: "MRP", width: 100, headerAlign: "center", align: "center" },
		// { field: "attribute_name", headerName: "Attribute Name", width: 120, headerAlign: "center", align: "center" },
		{ field: "attribute_value", headerName: "ATTRIBUTE", width: 130, headerAlign: "center", align: "center" },
		{ field: "ltl_qty", headerName: "PRV QTY", width: 70, align: "center", headerAlign: "center" },
		{
			field: "new_qty",
			headerName: "NEW QTY",
			width: 110,
			editable: true,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row }) => {
				return (
					<Box
						sx={{
							...DefRenderCellClass,
							backgroundColor: row.disabled ? grey[200] : "white",
							border: "none",
						}}>
						{row.new_qty}
					</Box>
				);
			},
		},
		{
			field: "ltl_dis",
			headerName: "PRV Dis",
			width: 70,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row }) => {
				return <span>{row.ltl_dis}%</span>;
			},
		},
		{
			field: "new_dis",
			headerName: "Dis%",
			width: 100,
			editable: true,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row }) => {
				return (
					<Box
						sx={{
							...DefRenderCellClass,
							backgroundColor: row.disabled ? grey[200] : "white",
							border: "none",
						}}>
						{row.new_dis}%
					</Box>
				);
			},
		},
		{
			field: "ltl_st",
			headerName: "PRV ST",
			width: 70,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row }) => {
				return <span>{row.ltl_st > 0 ? roundDecimalValueUptoTwo(row.ltl_st) : 0}%</span>;
			},
		},
		{
			field: "new_st",
			headerName: "ST%",
			width: 100,
			editable: true,
			align: "center",
			headerAlign: "center",
			renderCell: ({ row }) => {
				return (
					<Box
						sx={{
							...DefRenderCellClass,
							backgroundColor: row.disabled ? grey[200] : "white",
							border: "none",
						}}>
						{row.new_st}%
					</Box>
				);
			},
		},
		{
			field: "total_pcs",
			headerName: "Total Pieces",
			width: 90,
			headerAlign: "center",
			align: "center",
		},
	];

	// const [allMRP, setAllMRP] = useState([
	// 	{ id: "0", mrp: 1999, feburary: "", march: "", april: "", may: "" },
	// 	{
	// 		id: "1",
	// 		mrp: 2999,
	// 		feburary: "",
	// 		march: "",
	// 		april: "",
	// 		may: "",
	// 	},
	// 	{
	// 		id: "2",
	// 		mrp: 3999,
	// 		feburary: "",
	// 		march: "",
	// 		april: "",
	// 		may: "",
	// 	},
	// ]);

	const [updatedMrps, setUpdatedMrps] = useState([]);
	const [addNewMrp, setAddNewMrp] = useState();
	const [attributeOptions, setAttributeOptions] = useState();
	const [btnDisabled, setBtnDisabled] = useState(false);

	const handleRowDataChange = (updatedRow) => {
		const new_qty = parseInt(updatedRow.new_qty);
		const new_dis = parseInt(updatedRow.new_dis);
		const new_st = parseInt(updatedRow.new_st);

		let newObj;
		if (!isNaN(new_qty) && !isNaN(new_dis) && !isNaN(new_st)) {
			console.log("st", typeof new_qty, new_dis, new_st);
			const new_nsv = calculateNSVByMRPDis(new_qty, +updatedRow.mrp, new_dis);
			newObj = {
				...updatedRow,
				new_qty,
				new_dis,
				new_st,
				new_nsv,
				total_pcs: calculateTotalPcs(new_qty, new_st),
				new_asp: calculateAsp(new_nsv, new_qty),
				new_total_mrp: new_qty * updatedRow.mrp,
				completed: true,
			};
			console.log(newObj.new_total_mrp, new_qty, updatedRow.mrp, new_dis, new_nsv);
			setUpdatedMrps([...updatedMrps.filter((row) => row.id !== updatedRow.id), newObj]);
			return newObj;
		}
	};
	const handleMrpSaveClick = async () => {
		setBtnDisabled(true);
		const incompleteMrps = [];
		if (updatedMrps.length > 0) {
			let map = {};
			updatedMrps.forEach((row) => {
				if (row.new_st < 1 || row.new_dis < 1 || row.new_qty < 1 || row.total_pcs < 1) {
					if (row.new_st < 1 && row.new_dis < 1 && row.new_qty < 1) {
					} else incompleteMrps.push(row.mrp);
				}
				map[row.id] = { ...row };
			});
			console.log(updatedMrps, incompleteMrps);
			if (incompleteMrps.length > 0) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Fill All the values for MRPS : ${incompleteMrps.map((mrp) => mrp)}`,
					severity: "error",
				});
				setBtnDisabled(false);
				return;
			}
			let qty = 0;
			let new_total_mrp = 0;
			let total_pcs_count = 0;
			let new_nsv = 0;
			let current_count = 0,
				total_count = 0;
			const newUpdatedMrp = newAllMrps.map((row) => {
				let mrp = {};
				if (map[row.id] && map[row.id].mrp) {
					mrp = map[row.id];
				} else mrp = row;
				if (mrp.ltl_qty || mrp.new_qty > 0) {
					qty += mrp.new_qty;
					new_total_mrp += mrp.new_total_mrp;
					total_pcs_count += mrp.total_pcs;
					new_nsv += mrp.new_nsv;
					total_count++;
				}
				if (mrp.completed) current_count++;
				return mrp;
			});
			let st_avg = calculateSTByTotalPcsAndTotalQty(qty, total_pcs_count);
			let dis_avg = calculateDisByTotalMrpAndTotalNSV(new_total_mrp, new_nsv);
			// update subCategory Record
			const updatedSubCategory = {
				...selectedSubCategory,
				subCategoryMrps: newUpdatedMrp,
				new_qty: qty,
				new_dis: dis_avg,
				new_st: st_avg,
				new_total_mrp,
				new_asp: calculateAsp(new_nsv, qty),
				new_nsv,
				total_pcs: total_pcs_count,
				total_count,
				current_count,
				completed: total_count === current_count,
			};
			let cat_total_qty = 0,
				cat_total_mrp = 0,
				cat_total_pcs = 0,
				cat_new_nsv = 0,
				cat_cur_count = 0,
				cat_tot_count = 0;
			const updatedSubcategories = selectedCategory.categorySubCategories.map((row) => {
				let subCat = {};
				if (row.id === updatedSubCategory.id) subCat = updatedSubCategory;
				else subCat = row;
				if (subCat.ltl_qty || subCat.new_qty > 0) {
					cat_total_qty += subCat.new_qty;
					cat_total_pcs += subCat.total_pcs;
					cat_new_nsv += subCat.new_nsv;
					cat_total_mrp += subCat.new_total_mrp;
					cat_tot_count++;
				}
				if (subCat.completed) cat_cur_count++;

				return subCat;
			});
			// update Category Record
			const updatedCategory = {
				...selectedCategory,
				categorySubCategories: updatedSubcategories,
				new_qty: cat_total_qty,
				new_total_mrp: cat_total_mrp,
				new_dis: calculateDisByTotalMrpAndTotalNSV(cat_total_mrp, cat_new_nsv),
				new_st: calculateSTByTotalPcsAndTotalQty(cat_total_qty, cat_total_pcs),
				new_asp: calculateAsp(cat_new_nsv, cat_total_qty),
				new_nsv: cat_new_nsv,
				total_pcs: cat_total_pcs,
				total_count: cat_tot_count,
				current_count: cat_cur_count,
				completed: cat_tot_count === cat_cur_count,
			};

			let store_total_qty = 0,
				store_total_mrp = 0,
				store_total_pcs = 0,
				store_new_nsv = 0,
				store_cur_count = 0,
				store_tot_count = 0;

			const updatedAllCategories = selectedStore.storeCategories.map((row) => {
				let cat = {};
				if (row.id === updatedCategory.id) cat = updatedCategory;
				else cat = row;
				if (cat.ltl_qty || cat.new_qty > 0) {
					store_total_qty += cat.new_qty;
					store_total_mrp += cat.new_total_mrp;
					store_total_pcs += cat.total_pcs;
					store_new_nsv += cat.new_nsv;
					store_tot_count++;
				}
				if (cat.completed) store_cur_count++;

				return cat;
			});
			const derived_target = selectedStore.storeSeasonWiseData.filter(
				(row) => row.is_new_target === false && row.stage === selectedStore.stage
			)[0];
			const storeNewAsp = calculateAsp(store_new_nsv, store_total_qty);
			const storeNewDis = calculateDisByTotalMrpAndTotalNSV(store_total_mrp, store_new_nsv);
			const storeNewSt = calculateSTByTotalPcsAndTotalQty(store_total_qty, store_total_pcs);
			const storeNewRos = calculateRos(store_total_qty);

			const updatedDerivedTarget = {
				...derived_target,
				revenue: store_new_nsv,
				qty: store_total_qty,
				total_mrp: store_total_mrp,
				asp: storeNewAsp,
				dis: storeNewDis,
				st: storeNewSt,
				ros: storeNewRos,
			};
			const updatedStore = {
				...selectedStore,
				storeCategories: updatedAllCategories,
				storeSeasonWiseData: [
					...selectedStore.storeSeasonWiseData.filter((row) => row.id !== updatedDerivedTarget.id),
					updatedDerivedTarget,
				],
				total_qty: store_total_qty,
				new_total_mrp: store_total_mrp,
				new_dis: storeNewDis,
				new_st: storeNewSt,
				new_asp: storeNewAsp,
				new_nsv: store_new_nsv,
				total_pcs: store_total_pcs,
				total_count: store_tot_count,
				current_count: store_cur_count,
				completed: store_tot_count === store_cur_count,
			};

			let bp_total_qty = 0,
				bp_total_mrp = 0,
				bp_total_pcs = 0,
				bp_new_nsv = 0,
				bp_cur_count = 0;
			// bp_tot_count = 0; no total count becoz no. of stores are fixed | all stores must be filled

			const allUpdatedStores = selectedBp.bpStores.map((row) => {
				let store = {};
				if (row.id === updatedStore.id) store = updatedStore;
				else store = row;
				if (store.total_qty > 0) {
					bp_total_qty += store.total_qty;
					bp_total_mrp += store.new_total_mrp;
					bp_total_pcs += store.total_pcs;
					bp_new_nsv += store.new_nsv;
				}
				if (store.completed) bp_cur_count++;

				return store;
			});
			const updatedBp = {
				...selectedBp,
				bpStores: allUpdatedStores,
				total_qty: bp_total_qty,
				new_total_mrp: bp_total_mrp,
				new_dis: calculateDisByTotalMrpAndTotalNSV(bp_total_mrp, bp_new_nsv),
				new_st: calculateSTByTotalPcsAndTotalQty(bp_total_qty, bp_total_pcs),
				new_asp: calculateAsp(bp_new_nsv, bp_total_qty),
				new_nsv: bp_new_nsv,
				total_pcs: bp_total_pcs,
				current_count: bp_cur_count,
				completed: selectedBp.total_count === bp_cur_count,
			};

			await axios
				.put("/api/business_plan/bulk_update_mrps", {
					updatedMrps,
					updatedCategory,
					updatedStore: { ...updatedStore, storeCategories: [0] },
					updatedSubCategory,
					updatedBp: { ...updatedBp, bpStores: [0] },
					updatedDerivedTarget,
				})
				.then((res) => {
					setNewAllMrps([...newUpdatedMrp]);
					setSelectedSubCategory(updatedSubCategory);
					setSelectedCategory(updatedCategory);
					setSelectedStore(updatedStore);
					setSelectedBp(updatedBp);
					setUpdatedMrps([]);
					setModalOpen(false);
					setSnackBarOpen(true);
					setSnackBar({ ...snackBar, message: "changes saved", severity: "success" });
					console.log(updatedSubCategory, updatedCategory, updatedStore, updatedBp);
				})
				.catch((error) => {
					setSnackBarOpen(true);
					console.log(error);
					const response = error.response;
					if (response && response.data && response.data.status === "error")
						setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
					else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
				});
		} else {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "No Changes to update", severity: "error" });
		}
		setBtnDisabled(false);
	};
	const handleCancelMrpClick = () => {
		setUpdatedMrps([]);
		setModalOpen(false);
		setBtnDisabled(false);
	};
	const handleAddNewMrp = async (e) => {
		e.preventDefault();
		setBtnDisabled(true);
		const filteredMrp = newAllMrps.filter((row) => row.mrp === addNewMrp.mrp);
		if (filteredMrp.length) {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "MRP already Exists", severity: "error" });
		} else {
			const attributeName = selectedCategory?.categorySubCategories?.filter((row) => row.ltl_qty > 0)[0]
				.subCategoryMrps[0].attribute_name;
			const body = {
				...addNewMrp,
				sub_category_lev_bp_id: selectedSubCategory.id,
				sub_category: selectedSubCategory.sub_category,
				attribute_name: attributeName,
			};
			try {
				await axios
					.post("/api/business_plan/create_sub_cat_mrp", {
						body,
						subCatCount: selectedSubCategory.total_count,
					})
					.then((res) => {
						setNewAllMrps([...newAllMrps, res.data]);
						setAddNewMrp();
						setSnackBarOpen(true);
						setSnackBar({ ...snackBar, message: "MRP Added at last row", severity: "success" });
					});
				setBtnDisabled(false);
			} catch (error) {
				setBtnDisabled(false);
				setSnackBarOpen(true);
				const response = error.response;
				if (response && response.data && response.data.status === "error")
					setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
				else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
			}
		}
	};
	if (selectedBp && selectedStore && selectedCategory)
		return (
			<Stack width="1200px" margin="auto" pt={2} spacing={1}>
				<Button
					startIcon={<KeyboardBackspaceRounded />}
					variant="outlined"
					onClick={() => navigate(-1)}
					sx={{ m: 2, width: 100 }}>
					Back
				</Button>
				<Box display="flex" alignItems="center">
					<Typography variant="h5" p={2}>
						{selectedBp.name} / {selectedBp.channel} / {selectedStore.store_name}/{" "}
						{selectedCategory.category}
					</Typography>
				</Box>
				<Box display="flex" key={selectedSubCategory}>
					{/* <Typography width="50px">{row.category}</Typography>
					<Typography width="50px">{row.subCategory}</Typography> */}
					<DataGrid
						rows={subCategories}
						columns={SubCatTableColumns}
						columnHeaderHeight={35}
						getRowClassName={(params) =>
							params.row.completed
								? params.row.total_count !== 0
									? "green"
									: "yellow"
								: params.row.total_count === 0 && params.row.ltl_qty === 0
								? "yellow"
								: ""
						}
						sx={{
							// mb: 10,
							// background: grey[200],
							borderRadius: "8px",
							minHeight: 300,
							maxHeight: 480,
							"&.MuiDataGrid-root": {
								boxSizing: "none",
								boxShadow: `2px 2px 5px ${grey[200]}`,
							},
							// "& .MuiDataGrid-row": {
							// },
							"& .MuiDataGrid-row:first-child  ": {},
							"& .MuiDataGrid-cell": {
								padding: 0,
								borderBottom: "0px",
							},
							"& .MuiDataGrid-columnHeader": {
								padding: 0,
							},
							".MuiDataGrid-columnSeparator": {
								display: "none",
							},
							// "& .MuiDataGrid-cell:first-child ": {
							// 	padding: 0,
							// 	// background: grey[900],
							// },
						}}
						// getRowHeight={({ id, densityFactor }) => {
						// 	if (id === allMrps[0].allMrps[0].id) {
						// 		return 40 * densityFactor;
						// 	}

						// 	return null;
						// }}
						// rowSpacingType=""
						rowHeight={36}
						showCellVerticalBorder
						showColumnVerticalBorder
						onRowClick={(params, e) => handleSubCategorySelect(params, e)}
						hideFooter={true}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 30 },
							},
						}}
						disableRowSelectionOnClick
						pageSizeOptions={[30, 50]}
					/>
				</Box>

				<Modal
					sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
					open={modalOpen}
					onClose={() => setModalOpen(false)}>
					<Stack sx={modalStyle} spacing={2} alignItems="center">
						<DataGrid
							rows={newAllMrps ? newAllMrps : []}
							// getRowId={getRowId}
							isCellEditable={(params) => params.row.disabled === false}
							columns={mrpSSColumn}
							// showColumnVerticalBorder
							showCellVerticalBorder
							getRowClassName={(params) =>
								params.row.is_completed ? (params.row.total_count === 0 ? "yellow" : "green") : ""
							}
							// hideFooter={true}
							sx={{
								minHeight: 120,
								border: "none",
								width: "95%",
								"& .MuiDataGrid-row": {
									borderLeft: "1px solid lightgray",
									borderRight: "2px solid lightgray",
									cursor: "pointer",
									background: grey[200],
								},
								"& .MuiDataGrid-root": {
									// borderRight: "1px solid lightgray",
									cursor: "pointer",
									border: "none",
								},
								"& .MuiDataGrid-footerContainer": {
									maxHeight: "30px",
									minHeight: "30px",
									height: "10px",
									padding: "0px",
									// background: "grey",
								},
								"& .MuiDataGrid-cell": {
									padding: 0,
									// borderBottom: "0px",
								},
								// "& .MuiDataGrid-footerCell": {
								// },
							}}
							rowHeight={35}
							processRowUpdate={handleRowDataChange}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 10 },
								},
								columns: {
									columnVisibilityModel: {
										total_pcs: false,
									},
								},
							}}
							// columnVisibilityModel={{
							// 	// Hide columns status and traderName, the other columns will remain visible
							// }}
							disableRowSelectionOnClick
							pageSizeOptions={[5, 10]}
						/>

						<Box
							sx={{
								alignSelf: "center",
								justifyContent: "space-between",
								display: "flex",
								width: "100%",
							}}>
							<Box
								sx={{ alignSelf: "flex-start", display: "flex", flexWrap: "wrap" }}
								component="form"
								onSubmit={handleAddNewMrp}>
								<TextField
									size="small"
									value={addNewMrp?.mrp}
									onChange={(e) => setAddNewMrp({ ...addNewMrp, mrp: e.target.value })}
									variant="outlined"
									placeholder="Ex 2999"
									required
									sx={{ width: 150, pr: 1 }}
								/>
								<Select
									size="small"
									value={
										addNewMrp && addNewMrp.attribute_value ? addNewMrp.attribute_value : "select"
									}
									onChange={(e) => setAddNewMrp({ ...addNewMrp, attribute_value: e.target.value })}
									sx={{ width: 150, mr: 1 }}>
									<MenuItem value={"select"}>Select attribute Value</MenuItem>
									{attributeOptions &&
										attributeOptions.map((row) => (
											<MenuItem key={row} value={row}>
												{row}
											</MenuItem>
										))}
								</Select>
								<Button
									type="submit"
									variant="contained"
									sx={{ width: "100px" }}
									disabled={btnDisabled}>
									Add Mrp
								</Button>
							</Box>
							<Box>
								<Button
									onClick={handleCancelMrpClick}
									variant="outlined"
									sx={{ width: "100px", mr: 2 }}>
									Cancel
								</Button>
								<Button
									disabled={btnDisabled}
									onClick={handleMrpSaveClick}
									variant="contained"
									sx={{ width: "100px" }}>
									Save
								</Button>
							</Box>
						</Box>
					</Stack>
				</Modal>
			</Stack>
		);
	else navigate(`/${rootProtectedPath}/${services.business_plan.path}`);
};

export default SubCategoryPage;

// export const colorProductDummyData = [
// 	{
// 		total_qty: 0,
// 		id: 0,
// 		color_id: 0,
// 		updated_by: "",
// 		published_by: "",
// 		color_data: {
// 			url: "/images/pimImages/ProductImages/melton1.png",
// 			color: "WHITE",
// 			pallete: "PRIMARY",
// 			status: "DRAFTED",
// 			style_id: "1230",
// 			[productStyle]: {
// 				id: 0,
// 				id: "1230",
// 				style_name: "MELTON",
// 				season: "AW-23",
// 				brand: "RARE RABBIT",
// 				sub_brand: "RR",
// 				category: "SHIRT",
// 				sub_category: "SOLID",
// 				division: "APPARELS",
// 				status: "DRAFTED",
// 			},
// 		},

// 		[colorChannelBuyingObj]: [
// 			{
// 				channel: "EBO",
// 				total_qty: 0,
// 				created_by: "adarshh@houseofrare.com",
// 				updated_by: "adarshh@houseofrare.com",
// 				id: 0,
// 				[sizeLevelBuyingObj]: [
// 					{
// 						id: 0,
// 						total_qty: 0,
// 						size: "S",
// 						size_metadata_id: 0,
// 						channel_level_buying_id: 0,
// 					},
// 					{
// 						id: 1,
// 						total_qty: 0,
// 						size: "M",
// 						size_metadata_id: 0,
// 						channel_level_buying_id: 0,
// 					},
// 				],
// 			},
// 		],
// 	},
// ];
