import { ClearRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import axios from "axios";
import SelectInputField from "components/Pim/CommonComponents/InputComponents/SelectInputField";
import TextInputField from "components/Pim/CommonComponents/InputComponents/TextInputField";
import { GlobalState } from "context/GlobalContext";
import { attributeValueAssociationName, constraints, inputTypes } from "data/pimData";
import { useEffect, useState } from "react";

const RenderInputFields = ({ columns, setData, data, width }) => {
	console.log(data);
	return (
		<Box display="flex" width="calc(100%-280px)" flexWrap="wrap" p={1} alignItems="flex-start" columnGap="3%" rowGap="2px">
			{Object.keys(columns)?.map((columnName) => {
				const column = columns[columnName];
				console.log(columnName);
				if (column.input_type === inputTypes.enter || inputTypes.autoGenerate)
					return (
						<TextInputField
							sx={{
								width: "55%",
								p: 1,
								background: grey[200],
								fontWeight: 400,
								overflow: "hidden",
								boxSizing: "border-box",
							}}
							data={data}
							setData={setData}
							column={column}
							disabled={column.input_type == inputTypes.autoGenerate}
							width={column.type.datatype.includes("long") ? "70%" : width}
							required={column.type[constraints.notNull]}
							type={column.type.datatype}
						/>
					);
				else {
					return (
						<SelectInputField
							sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}
							data={data}
							setData={setData}
							column={column}
							width={width}
							options={column?.[attributeValueAssociationName]}
							required={column.type[constraints.notNull]}
						/>
					);
				}
			})}
		</Box>
	);
};

const EditModal = ({ modalOpen, setModalOpen, selectedStore, columns, submitFunction }) => {
	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const [selectedStoreData, setSelectedStoreData] = useState({});
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setSelectedStoreData(selectedStore);
	}, [selectedStore]);

	const handleUpdate = () => {
		setLoading(true);

		axios
			.put("/api/pos_reco/update_store_data", selectedStoreData)
			.then((res) => {
				handleCloseModal();
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Store Updated `,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			})
			.catch((err) => {
				handleCloseModal();
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Unable to update Store `,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
	};

	return (
		<Dialog open={modalOpen} onClose={handleCloseModal} scroll="body" maxWidth="md" fullWidth>
			<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center" component={"form"}>
				<Box display="flex" alignItems="center">
					<DialogTitle>Edit Store</DialogTitle>
				</Box>

				<IconButton sx={{ width: "50px", height: "50px", marginLeft: "auto" }} onClick={handleCloseModal}>
					<ClearRounded sx={{ transform: "scale(1.3)" }} />
				</IconButton>
			</Box>
			<Box p={2}>
				<RenderInputFields data={selectedStoreData} setData={setSelectedStoreData} width="30%" columns={columns} />
				<DialogActions sx={{ paddingRight: 4 }}>
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="contained"
						onClick={handleUpdate}
						startIcon={loading ? <CircularProgress color="inherit" size="25px" /> : ""}
						disabled={loading}>
						{loading ? "Saving" : "Save"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default EditModal;
