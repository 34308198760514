import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import EboForm from "./EboForm";
import { DataGrid } from "@mui/x-data-grid";
import { StoreContext } from "../EboStore";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import FileSaver from "file-saver";
import StoreDetail from "../ActiveStore/ActiveStore";
import EmployeeDetail from "../NsmScreen/NsmScreen";
import { AuthState } from "context/AuthContext";
import { custom, services } from "data/authData";
import moment from "moment/moment";

const columns = [
	{
		field: "rr_code",
		headerName: "STORE-CODE",
		width: 100,
		headerClassName: "super-app-theme--header",
		renderCell: ({ value, row }) => `${row?.rr_code || ""}${row?.rr_number || ""}`,
	},
	{
		field: "brand",
		headerName: "BRAND",
		headerClassName: "super-app-theme--header",
		width: 100,
	},
	{
		field: "store_name",
		headerName: "STORE NAME",
		headerClassName: "super-app-theme--header",
		width: 130,
	},
	{
		field: "name_of_franchise_company",
		headerName: "FRANCHISE NAME",
		headerClassName: "super-app-theme--header",
		width: 180,
	},
	{
		field: "address",
		headerClassName: "super-app-theme--header",
		headerName: "ADDRESS",
		width: 200,
	},
	{
		field: "state",
		headerClassName: "super-app-theme--header",
		headerName: "STATE",
		type: "string",
		width: 120,
	},
	{
		field: "city",
		headerClassName: "super-app-theme--header",
		headerName: "CITY",
		type: "string",
		width: 120,
	},
	{
		field: "pincode",
		headerClassName: "super-app-theme--header",
		headerName: "PINCODE",
		width: 140,
	},
];

function EboFormV2() {
	const navigate = useNavigate();
	const [downloading, setDownloading] = useState(false);
	const [open, setOpen] = useState(false);
	const [action, setAction] = useState("CREATE");
	const [eboStores, setEboStores] = useState([]);
	const [value, setValue] = useState({});
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { user } = AuthState();

	useEffect(() => {
		axios
			.get("/api/store/active-store")
			.then((res) => {
				setEboStores(res.data);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	}, []);

	const handleDownload = async () => {
		try {
			setDownloading(true);
			await axios({
				url: `/api/store/download-active-store`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `Active_store_${new Date().toJSON().slice(0, 10)}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Failed to download file",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	return (
		<Stack maxWidth="1100px" margin="auto" spacing={0}>
			<Box pt={0}>
				<Button
					startIcon={<KeyboardBackspaceRounded />}
					variant="outlined"
					onClick={() => navigate(-1)}
					sx={{ width: 100, my: 3 }}>
					Back
				</Button>
			</Box>

			<Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
				<Typography variant="h4" width="100%" p={0}>
					EBO Stores
				</Typography>

				<Box display="flex" alignItems="center" columnGap={3}>
					<Button
						disabled={downloading}
						onClick={handleDownload}
						startIcon={downloading ? <CircularProgress color="inherit" size="20px" /> : <ArrowDownwardRoundedIcon />}
						sx={{ height: "50px" }}
						variant="contained">
						DOWNLOAD
					</Button>
					<Button
						variant="contained"
						disabled={user.role.VM || user.role.NSM ? true : false}
						sx={{ height: "50px" }}
						startIcon={<AddRoundedIcon />}
						onClick={() => {
							setOpen(true);
							setAction("CREATE");
						}}>
						CREATE
					</Button>
				</Box>

				<Stack
					sx={{
						maxWidth: 1100,
						bgcolor: "background.paper",
						borderRadius: 2,
						maxHeight: 500,
						overflow: "auto",
						margin: "auto",
						my: 4,
					}}
					key={value}>
					{action === "UPDATE" ? (
						<EboForm
							data={value}
							action={action}
							setAction={setAction}
							open={open}
							setOpen={setOpen}
							activeStore={eboStores}
							setActiveStore={setEboStores}
						/>
					) : action === "VM" ? (
						<StoreDetail
							data={value}
							activeStore={eboStores}
							setActiveStore={setEboStores}
							setOpen={setOpen}
							action={action}
							open={open}
						/>
					) : action === "NSM" ? (
						<EmployeeDetail
							data={value}
							activeStore={eboStores}
							setActiveStore={setEboStores}
							setOpen={setOpen}
							action={action}
							open={open}
						/>
					) : action === "CREATE" ? (
						<EboForm
							action={action}
							setAction={setAction}
							open={open}
							setOpen={setOpen}
							activeStore={eboStores}
							setActiveStore={setEboStores}
						/>
					) : (
						""
					)}
				</Stack>
			</Box>
			<DataGrid
				rows={eboStores}
				columns={columns}
				autoHeight
				sx={{
					"& .super-app-theme--header": {
						// backgroundColor: grey[300],
					},
					minHeight: "65vh",
					minWidth: "100%",
					cursor: "pointer",
				}}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 5,
						},
					},
				}}
				pageSizeOptions={[5, 10]}
				disableRowSelectionOnClick
				onRowClick={(params) => {
					console.log("HIT");
					setValue({ ...params.row, eboStoreVmData: { ...params.row.eboStoreVmData } });
					user.role[custom.MDM.key] === true
						? setAction("UPDATE")
						: user.role[custom.VM.key] === true
						? setAction("VM")
						: user.role[custom.NSM.key] === true
						? setAction("NSM")
						: setAction("CREATE");

					setOpen(true);
				}}
			/>
		</Stack>
	);
}

export default EboFormV2;
