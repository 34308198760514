import { CloudUpload } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { useEffect, useState } from "react";
import ArticleIcon from "@mui/icons-material/Article";

const RecoDocuments = () => {
	const [uploadDialog, setUploadDialog] = useState(false);
	const [docType, setDocType] = useState("Logistics");
	const [effectiveDate, setEffectiveDate] = useState("");
	const [file, setFile] = useState("");
	const [folders, setFolders] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	useEffect(() => {
		getUploadedFiles();
	}, []);

	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		const newFileName = `${effectiveDate}_${docType}_${newFile.name}`;
		const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
		setFile(modifiedFile);
	};

	const commissionColmn = [
		{
			field: "effective_date",
			headerName: "Effective Date",
			flex: 1,
			width: 220,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "gender",
			headerName: "Gender",
			width: 220,
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "apparel",
			headerName: "Apparel",
			width: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "footwear",
			headerName: "Footwear",
			width: 180,
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "accessories",
			headerName: "Accessories",
			width: 164,
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
	];

	const rows = [
		{
			id: 1,
			effective_date: "17-07-2020",
			gender: "Men",
			apparel: 19,
			footwear: 14,
			accessories: 17,
		},
		{
			id: 2,
			effective_date: "17-07-2020",
			gender: "Women",
			apparel: 19,
			footwear: 17,
			accessories: 17,
		},
		{
			id: 3,
			effective_date: "17-07-2020",
			gender: "Kids",
			apparel: 19,
			footwear: 17,
			accessories: 17,
		},
		{
			id: 4,
			effective_date: "17-07-2020",
			gender: "Unisex",
			apparel: 19,
			footwear: 17,
			accessories: 17,
		},
		{
			id: 5,
			effective_date: "01-11-2023",
			gender: "Men",
			apparel: 13,
			footwear: 13,
			accessories: 13,
		},
		{
			id: 6,
			effective_date: "01-11-2023",
			gender: "Women",
			apparel: 13,
			footwear: 13,
			accessories: 13,
		},
		{
			id: 7,
			effective_date: "01-11-2023",
			gender: "Kids",
			apparel: 13,
			footwear: 13,
			accessories: 13,
		},
		{
			id: 8,
			effective_date: "01-11-2023",
			gender: "Unisex",
			apparel: 13,
			footwear: 13,
			accessories: 13,
		},
	];

	const handleFileUpload = async () => {
		let form = new FormData();
		form.append("file", file);
		form.append("folderName", "myntra");
		setLoadingOpen(true);
		await axios
			.post("/api/online_reco/myntra/upload-files", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				setUploadDialog(false);
				setEffectiveDate("");
				let { name, publicUrl } = res.data;
				setSnackBarOpen(true);
				getUploadedFiles();
				setSnackBar({
					...snackBar,
					message: "File uploaded successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return "Error in file Upload";
			});
		setLoadingOpen(false);
	};

	const getUploadedFiles = async () => {
		await axios
			.get("/api/online_reco/myntra/getfiles")
			.then((res) => {
				setFolders(res?.data?.folders);
				// setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "File uploaded successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return "Error in file Upload";
			});
		// getFolderContent(breadCrumb[breadCrumb.length - 1]);
		// setLoadingOpen(false);
	};

	const handleClose = () => {
		setUploadDialog(false);
		setEffectiveDate("");
	};
	const columns = [
		{
			field: "name",
			headerName: folders && folders.length && folders[0].isFolder ? "Folders" : "Files",
			width: 500,
			renderCell: ({ row }) => {
				return (
					<Box display={"flex"} columnGap={"10px"} alignItems={"center"}>
						<Avatar sx={{ width: 34, height: 34 }}>
							<ArticleIcon fontSize="small" />
						</Avatar>
						<Typography>{row.name}</Typography>
					</Box>
				);
			},
		},
	];
	function generatePuclicUrl(fileName) {
		const url = `https://storage.googleapis.com/rarebox_files/online_reco_documents/myntra/${fileName}`;
		console.log("url", url);
		window.open(url, "_blank");
	}

	const filteredFiles = folders.filter((file) => file.name !== "");
	return (
		<Stack>
			<Stack width="100%" sx={{ mb: 3 }}>
				<Typography variant="h5" pt={2} pb={2}>
					Commissions
				</Typography>
				<DataGrid
					// key={selectedData}
					// getRowId={(row) => row.month_year}
					rows={rows}
					columns={commissionColmn}
					showCellVerticalBorder
					showColumnVerticalBorder
					disableRowSelectionOnClick
					hideFooter
					unstable_rowSpanning
					sx={{
						maxHeight: "300px",
						"& .MuiDataGrid-row:hover": {
							backgroundColor: "transparent",
						},
						"& .MuiDataGrid-row": {
							alignItems: "center",
							display: "flex",
							justifyContent: "center",
						},
						minHeight: 100,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
					}}
				/>
			</Stack>
			<Stack display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h5" pt={2} pb={2}>
					Documents
				</Typography>
				<Button
					component="label"
					role={undefined}
					variant="contained"
					tabIndex={-1}
					onClick={() => setUploadDialog(true)}
					startIcon={<CloudUpload />}>
					Upload file
				</Button>
			</Stack>
			<Divider />
			<DataGrid
				onRowClick={({ row }) => {
					generatePuclicUrl(row.name);
				}}
				getRowId={(row) => row.name}
				rows={filteredFiles}
				columns={columns}
				key={folders}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 5,
						},
					},
					columns: {
						columnVisibilityModel: {
							created_at: folders && folders.length ? !folders[0].isFolder : true,
						},
					},
				}}
				sx={{
					minHeight: 350,
					maxHeight: 430,
					border: "none",
					"& .MuiDataGrid-row": {
						// borderBottom: "1px solid lightgray",
						cursor: "pointer",
						border: "none",
					},
					"& .MuiDataGrid-columnHeader": {
						cursor: "pointer",
					},
				}}
				pageSizeOptions={[5]}
				disableRowSelectionOnClick
			/>
			<Dialog
				open={uploadDialog}
				// onClose={() => setUploadDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm">
				<DialogTitle id="alert-dialog-title">Upload File</DialogTitle>
				<DialogContent>
					<Stack display="flex" flexDirection="row" alignContent="center" paddingBottom="10px">
						<Box pr="50px">
							<InputLabel>Effective Date*</InputLabel>
							<TextField
								size="small"
								type="text"
								value={effectiveDate}
								onChange={(e) => setEffectiveDate(e.target.value)}
								placeholder="eg: dd-mm-yyyy fromat"
								required
							/>
						</Box>
						<Box>
							<InputLabel>Type*</InputLabel>
							<Select
								size="small"
								width="100px"
								value={docType}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								onChange={(e) => setDocType(e.target.value)}>
								<MenuItem value={"Logistics"}>Logistics</MenuItem>
								<MenuItem value={"Commission"}>Commission</MenuItem>
							</Select>
						</Box>
					</Stack>
					<Stack display="flex" flexDirection="row" alignContent="center">
						<Box pr="50px">
							<InputLabel>Upload File*</InputLabel>
							<TextField
								size="small"
								type={"file"}
								// value={data && data[column.key] ? data[column.key] : ""}
								onChange={(e) => handleFileChange(e)}
							/>
						</Box>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>
						Cancle
					</Button>
					<Button variant="contained" disabled={file === "" || effectiveDate === ""} onClick={handleFileUpload}>
						Upload
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default RecoDocuments;
