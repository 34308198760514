import { Box, Breadcrumbs, Button, Divider, Typography, Link, CircularProgress, Avatar } from "@mui/material";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { GlobalState } from "context/GlobalContext";
import FolderIcon from "@mui/icons-material/Folder";
import ArticleIcon from "@mui/icons-material/Article";

import React, { useEffect, useState } from "react";
import { CloudUpload, KeyboardBackspaceRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { AuthState } from "context/AuthContext";
import SearchBar from "components/ReusableComponents/SearchBar";
import { CommonFolderUI } from "../DataHubRSM/DataHubRSM";
import { admin, departments } from "data/authData";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

function DataHubBoardData() {
	const [folders, setFolders] = useState([]);
	const [breadCrumb, setBreadCrumb] = useState(["HOME"]);
	const [loading, setLoading] = useState(false);
	const [fileUpload, setFileUpload] = useState(false);
	const [files, setFiles] = useState({});
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const [search, setSearch] = useState("");
	const navigate = useNavigate();
	const { user } = AuthState();
	let commonFolders = [];
	let usersFolders = [];
	const getFolders = async () => {
		setLoading(true);
		setLoadingOpen(true);
		await axios
			.get(`/api/board_dh/get-contents`)
			.then((res) => {
				console.log(res.data);
				if (res.status === 200) {
					const foldersList = res.data;
					setFolders([...foldersList]);
					setLoading(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
		setLoadingOpen(false);
	};

	const columns = [
		{
			field: "name",
			headerName: "Files",
			width: 700,
			renderCell: ({ row }) => {
				return (
					<Box display={"flex"} columnGap={"10px"} alignItems={"center"}>
						<Avatar sx={{ width: 34, height: 34 }}>
							{row?.isFolder ? <FolderIcon fontSize="small" /> : <ArticleIcon fontSize="small" />}
						</Avatar>
						<Typography>{row.name}</Typography>
					</Box>
				);
			},
		},
		{
			field: "created_at",
			headerName: "CREATED AT",
			width: 200,
			valueGetter: (value, row) => {
				console.log("ROW", row, value);
				return moment(row?.created_at).format("DD/MM/YYYY - HH:mm");
			},
		},
	];

	function getRowId(row) {
		return row.name;
	}

	useEffect(() => {
		getFolders();
	}, []);

	const getFolderContent = async (folderName) => {
		setLoading(true);
		setLoadingOpen(true);
		let newArr = [];
		newArr = [...breadCrumb];

		if (!newArr.includes(folderName)) newArr.push(folderName);
		await axios
			.post(`/api/board_dh/get-files`, { newArr })
			.then((res) => {
				if (res.status === 200) {
					const { folders } = res.data;
					setFolders([...folders]);
					setLoading(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
		setLoadingOpen(false);
	};

	const handleBreadCrumb = (value) => {
		let newArr = breadCrumb;
		newArr.splice(breadCrumb.indexOf(value) + 1, breadCrumb.length);
		setBreadCrumb([...newArr]);
		value === "HOME" ? getFolders() : getFolderContent(value);
	};

	const handleFileUpload = async (files) => {
		let form = new FormData();
		form.append("file", files);
		form.append("folderName", JSON.stringify(breadCrumb));
		setFiles({});
		setFileUpload(true);
		setLoadingOpen(true);
		await axios
			.post("/api/board_dh/upload-files", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				let { name, publicUrl } = res.data;
				if (name && publicUrl) {
					setFileUpload(false);
				}
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "File uploaded successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setFileUpload(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return "Error in file Upload";
			});
		getFolderContent(breadCrumb[breadCrumb.length - 1]);
		setLoadingOpen(false);
	};

	// helper function to handle file uploads
	async function handleFileChange(e) {
		const newFile = e.target.files[0];
		const modifiedFile = new File([newFile], newFile.name, { type: newFile.type });
		console.log(modifiedFile);
		// setFiles(modifiedFile);
		await handleFileUpload(modifiedFile);
	}

	function generatePuclicUrl(fileName) {
		const url = `https://storage.googleapis.com/board_data_dh/${breadCrumb[1]}/${fileName}`;
		console.log("url", url);
		window.open(url, "_blank");
	}

	function handleSearchSubmit() {
		if (search) {
			let newArr = [];
			let regex = new RegExp(search, "i");
			usersFolders.forEach((folder) => {
				console.log(search);
				console.log(regex.test(folder.name));
				if (regex.test(folder.name)) {
					newArr.push(folder);
				}
			});
			if (newArr.length) {
				setFolders(newArr);
			}
		}
	}
	let disableUpload = false;
	// let userAccess = loginUser.role[departments.buying_planning.key] || loginUser.role[admin];
	// if (breadCrumb.length === 2) {
	// 	if (breadCrumb[1] === "MBO" && (loginUser.role?.MBO === true || userAccess)) {
	// 		disableUpload = false;
	// 	} else if (breadCrumb[1] === "LFS" && (loginUser.role?.LFS === true || userAccess)) {
	// 		disableUpload = false;
	// 	} else if (breadCrumb[1] === "BUYING_DATA" && (loginUser.role?.BUYING_DATA === true || userAccess)) {
	// 		disableUpload = false;
	// 	} else if (user.employee_id == breadCrumb[1].split("-")[1]) {
	// 		disableUpload = false;
	// 	}
	// }
	folders.forEach((folder) => {
		if (folder.name.split("-").length === 1 && folder.name !== "") {
			if (folder["isFolder"]) {
				commonFolders.push(folder);
			} else {
				usersFolders.push(folder);
			}
		} else if (folder.name !== "") {
			usersFolders.push(folder);
		}
	});
	const handleFolderClick = (folder) => {
		setBreadCrumb([...breadCrumb, folder.name]);
		getFolderContent(folder.name);
		if (folder.isFile) {
			generatePuclicUrl(folder.name);
		}
	};
	const filtered = usersFolders.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));

	return (
		<Box>
			<Box
				direction="row"
				width="100%"
				justifyContent="space-around"
				flexWrap="wrap"
				maxWidth="1200px"
				margin={"auto"}>
				<Stack spacing={0} margin="auto" direction={"row"} mt={3}>
					<Box width="100%">
						<Box mb={2} pt={0}>
							<Button
								startIcon={<KeyboardBackspaceRounded />}
								variant="outlined"
								onClick={() => navigate("/explore")}
								sx={{ width: 100 }}>
								Back
							</Button>
						</Box>
						<Box display={"flex"} justifyContent={"space-between"}>
							<Typography variant="h4" pt={0}>
								RTPL Board Data
							</Typography>

							<Button
								component="label"
								role={undefined}
								variant="contained"
								tabIndex={-1}
								sx={{ display: fileUpload || breadCrumb.length === 1 || disableUpload ? "none" : "" }}
								disabled={fileUpload || breadCrumb.length === 1 || disableUpload}
								startIcon={fileUpload ? <CircularProgress color="inherit" /> : <CloudUpload />}>
								{fileUpload ? "Uploading" : "Upload file"}
								<VisuallyHiddenInput
									type="file"
									onClick={(e) => (e.target.value = null)}
									onChange={handleFileChange}
								/>
							</Button>
						</Box>
						<Divider sx={{ width: "100%" }}></Divider>
						<Box display={"flex"} justifyContent={"space-between"}>
							<Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3 }}>
								{breadCrumb.map((value) => (
									<Link
										key={value}
										underline="hover"
										color="inherit"
										sx={{ fontSize: 20, cursor: "pointer" }}
										onClick={() => handleBreadCrumb(value)}>
										{value}
									</Link>
								))}
							</Breadcrumbs>
							<Box mt={2} display={"flex"}>
								{" "}
								<SearchBar
									value={search}
									setValue={setSearch}
									placeholder={"Enter folder/file name to search"}
									submitFunction={handleSearchSubmit}
								/>{" "}
							</Box>
						</Box>
					</Box>
				</Stack>
				<CommonFolderUI
					commonFolders={commonFolders}
					handleFolderClick={(folder) => handleFolderClick(folder)}
				/>

				<DataGrid
					onRowClick={({ row }) => {
						setBreadCrumb([...breadCrumb, row.name]);
						getFolderContent(row.name);
						if (!row.isFolder) {
							generatePuclicUrl(row.name);
						}
					}}
					getRowId={getRowId}
					rows={usersFolders}
					columns={columns}
					key={folders}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
						// columns: {
						// 	columnVisibilityModel: {
						// 		created_at: folders && folders.length ? !folders[0].isFolder : true,
						// 	},
						// },
					}}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
					}}
					pageSizeOptions={[5]}
					disableRowSelectionOnClick
				/>
			</Box>
		</Box>
	);
}

export default DataHubBoardData;
