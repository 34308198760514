export const admin = "ADMIN";

export const rootProtectedPath = "explore";

export const departments = {
	sales_and_ops: { name: "SALES & OPERATIONS", key: "SALES_OPS" },
	ecommerce: { name: "ECOMMERCE", key: "ECOMMERCE" },
	tech: { name: "TECH", key: "TECH" },
	buying_planning: { name: "BUYING & PLANNING", key: "BUYING_PLANNING" },
	finanace_accounts: { name: "FINANCE & ACCOUNTS", key: "FINANCE_ACCOUNTS" },
	hr_admin: { name: "HR & ADMIN", key: "HR_ADMIN" },
};

export const services = {
	users: { name: "Users", key: "USERS", path: "users", department: departments.hr_admin },
	radhamani_web_data: {
		name: "Web Data",
		key: "RADHAMANI_WEB_DATA",
		path: "radhamani_web_data",
		department: departments.hr_admin,
	},
	business_plan: {
		name: "Business Plan",
		key: "BUSINESS_PLAN",
		path: "business_plan",
		department: departments.buying_planning,
	},
	ecom_reports: {
		name: "ECOM Reports",
		key: "ECOM_REPORTS",
		path: "ecom_reports",
		department: departments.buying_planning,
	},
	supplier_dashboard: {
		name: "Supplier Dashboard",
		path: "supplier_dashboard",
		key: "supplier_dashboard",
		department: departments.hr_admin,
	},
	data_hub: {
		name: "Data Hub",
		path: "data_hub",
		key: "data_hub",
		department: departments.buying_planning,
	},
	online_reco: {
		name: "Myntra Reco",
		path: "online_reco",
		key: "ONLINE_RECO",
		department: departments.finanace_accounts,
	},
	ebo_stores: { name: "EBO Store", key: "EBO_STORE", path: "ebo_stores", department: departments.tech },
	mbo_stores: { name: "MBO - LFS Store", key: "MBO_LFS_STORE", department: departments.tech, path: "mbo_lfs_stores" },
	pim: { name: "PIM", key: "PIM", path: "pim", department: departments.tech },
	item_master: { name: "Item Master", key: "ITEM_MASTER", path: "item_master", department: departments.tech },
	production_costing: {
		name: "Production Costing",
		key: "PRODUCTION_COSTING",
		path: "production_costing",
		department: departments.finanace_accounts,
	},
	uc_vs_ginesys: {
		name: "UC VS GINESYS",
		key: "UC_VS_GINESIS",
		path: "uc_vs_ginesys",
		department: departments.finanace_accounts,
	},
	ebo_store_pos_master: {
		name: "Store POS Master",
		key: "ebo_store_pos_master",
		path: "ebo_store_pos_master",
		department: departments.finanace_accounts,
	},
	ebo_payments: {
		name: "Franchise Payments",
		key: "EBO_PAYMENTS",
		path: "ebo_payments",
		department: departments.finanace_accounts,
	},
	pos_reco: {
		name: "POS Collection Reco",
		key: "POS_RECO",
		path: "pos_reco",
		department: departments.finanace_accounts,
	},
	custom_queries: {
		name: "Custom Queries",
		key: "CUSTOM_QUERIES",
		path: "custom_queries",
		department: departments.buying_planning,
	},
};
export const custom = {
	RSM: { name: "RSM", key: "RSM" },
	NSM: { name: "NSM", key: "NSM" },
	VM: { name: "VM", key: "VM" },
	MDM: { name: "MDM", key: "MDM" },
	COGS: { name: "COGS", key: "COGS" },
	WORKFORCEADMIN: { name: "WORKFORCE ADMIN", key: "WORKFORCEADMIN" },
	CASHCARDADMIN: { name: "CASHCARD ADMIN", key: "CASHCARDADMIN" },
	RARE_RABBIT: { name: "RARAE RABBIT", key: "RARE_RABBIT" },
	RAREISM: { name: "RAREISM", key: "RAREISM" },
	RAREONES: { name: "RAREONES", key: "RAREONES" },
	MBO: { name: "MBO", key: "MBO" },
	LFS: { name: "LFS", key: "LFS" },
	EBO: { name: "EBO", key: "EBO" },
	FINANCE_VIEWER: { name: "FINANCE VIEWER", key: "FINANCE_VIEWER" },
	FINANCE: { name: "FINANCE", key: "FINANCE" },
	BUYING_DATA: { name: "BUYING DATA", key: "BUYING_DATA" },
	STORE_ADMIN: { name: "Store Admin", key: "STORE_ADMIN" },
	AGREEMENT_ADMIN: { name: "AGREEMENT_ADMIN", key: "AGREEMENT_ADMIN" },
	BOARD: { name: "BOARD", key: "BOARD" },
	HR: { name: "HR", key: "HR" },
};
export const externalTypes = {
	SUPPLIER: { name: "Supplier", key: "SUPPLIER" },
	STORE: { name: "Store", key: "STORE" },
};

export const userTypes = {
	internal: "INTERNAL",
	external: "EXTERNAL",
};
