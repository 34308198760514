export const RR_RSM_THOR = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "RR",
		value: "RR",
	},
	{
		label: "RSM",
		value: "RSM",
	},
	{
		label: "THOR",
		value: "THOR",
	},
];

const BRAND_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "RARE RABBIT",
		value: "RARE RABBIT",
	},
	{
		label: "RAREISM",
		value: "RAREISM",
	},
	{
		label: "THOR",
		value: "THOR",
	},
];

export const TIER_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "TIER1",
		value: "TIER1",
	},
	{
		label: "TIER2",
		value: "TIER2",
	},
	{
		label: "TIER3",
		value: "TIER3",
	},
];

export const STORE_TYPE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "COCO",
		value: "COCO",
	},
	{
		label: "COFO",
		value: "COFO",
	},
	{
		label: "FOFO",
		value: "FOFO",
	},
	{
		label: "FOCO",
		value: "FOCO",
	},
];

export const MALL_HS_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "MALL",
		value: "MALL",
	},
	{
		label: "HIGH STREET",
		value: "HIGH STREET",
	},
	{
		label: "HOTEL",
		value: "HOTEL",
	},
	{
		label: "AIRPORT",
		value: "AIRPORT",
	},
];

export const AGREEMENT_REGISTERED_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "Yes",
		value: "YES",
	},
	{
		label: "No",
		value: "NO",
	},
];

export const GST_REGISTERED_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "N/A",
		value: "N/A",
	},
	{
		label: "REGISTERED",
		value: "REGISTERED",
	},
	{
		label: "TO BE REGISTERED",
		value: "TO BE REGISTERED",
	},
];

export const IS_ACTIVE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "PENDING",
		value: "PENDING",
	},
	{
		label: "ACTIVE",
		value: "ACTIVE",
	},
	{
		label: "CLOSED",
		value: "CLOSED",
	},
	{
		label: "INACTIVE",
		value: "INACTIVE",
	},
];

export const ZONE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "NORTH",
		value: "NORTH",
	},
	{
		label: "SOUTH",
		value: "SOUTH",
	},
	{
		label: "EAST",
		value: "EAST",
	},
	{
		label: "WEST",
		value: "WEST",
	},
	{
		label: "CENTRAL",
		value: "CENTRAL",
	},
];

export const RENT_SCHEDULE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ADVANCE",
		value: "ADVANCE",
	},
	{
		label: "MONTH END",
		value: "MONTH END",
	},
];

export const requiredFields = [
	{
		label: "RR/RSM/THOR",
		db_field: "rr_code",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...RR_RSM_THOR],
		default_value: "SELECT",
	},
	{
		label: "RR Code",
		db_field: "rr_number",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Brand",
		db_field: "brand",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...BRAND_OPTIONS],
		default_value: "SELECT",
		disable: true,
	},
	{
		label: "Reporting Name",
		db_field: "store_name",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Store Type",
		db_field: "store_type",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...STORE_TYPE_OPTIONS],
		default_value: "SELECT",
	},
	// {
	// 	label: "Store Type As Per Acc",
	// 	db_field: "store_type_as_per_acc",
	// 	required: false,
	// 	datatype: "TEXT",
	// 	type: "SELECT",
	// 	options: [...STORE_TYPE_OPTIONS],
	// 	default_value: "SELECT",
	// },
	{
		label: "Name Of Franchise / Company",
		db_field: "name_of_franchise_company",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Pincode",
		db_field: "pincode",
		required: true,
		datatype: "NUMBER",
		custom: true,
		params: "state",
	},
	{
		label: "Unit No",
		db_field: "unit_no",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Floor",
		db_field: "floor",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Address",
		db_field: "address",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "State",
		db_field: "state",
		required: true,
		datatype: "TEXT",
		disable: true,
	},
	{
		label: "City",
		db_field: "city",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Tier",
		db_field: "tier",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...TIER_OPTIONS],
		default_value: "SELECT",
	},

	// {
	// 	label: "Billing Name As Per Agreement",
	// 	db_field: "billing_name_as_per_agreement",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Billing Name As Per Account",
	// 	db_field: "billing_name_as_per_account",
	// 	required: false,
	// 	datatype: "TEXT",
	// },

	{
		label: "Mall / HS",
		db_field: "mall_hs",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...MALL_HS_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Zone",
		db_field: "zone",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...ZONE_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Carpet Area",
		db_field: "carpet_area",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Chargable Area",
		db_field: "chargable_area",
		required: true,
		datatype: "",
	},
	// {
	// 	label: "Facade In Ft",
	// 	db_field: "facade_in_ft",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Security Deposit As Per Agreement",
	// 	db_field: "security_deposit_as_per_agreement",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Security Deposit As Per Account",
	// 	db_field: "security_deposit_as_per_account",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "CAM Deposit As Per Agreement",
	// 	db_field: "cam_deposit_as_per_agreement",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "CAM Deposit As Per Account",
	// 	db_field: "cam_deposit_as_per_account",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Store Opening Date",
	// 	db_field: "store_opening_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },

	// {
	// 	label: "Rental Start Date",
	// 	db_field: "rental_start_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	// {
	// 	label: "Financial Year",
	// 	db_field: "financial_year",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	{
		label: "Electricity Deposit As Per Account",
		db_field: "electricity_deposit_as_per_acc",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Electricity Deposit As Per Agreement",
		db_field: "electricity_deposit_as_per_agreement",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Security Deposit As Per LOI",
		db_field: "security_deposit_as_per_loi",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "CAM Deposit As Per LOI",
		db_field: "cam_deposit_as_per_loi",
		required: true,
		datatype: "NUMBER",
	},

	// FITOUT
	{
		label: "Fitout Period",
		db_field: "fitout_period",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Fitout Start Date",
		db_field: "fitout_start_date",
		required: true,
		datatype: "DATE",
	},

	{
		label: "Fitout End Date",
		db_field: "fitout_end_date",
		required: true,
		datatype: "DATE",
	},

	// TERM
	{
		label: "Lease Term",
		db_field: "lease_term",
		required: true,
		datatype: "TEXT",
	},
	// {
	// 	label: "Lease Term Start Date",
	// 	db_field: "lease_start_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	// {
	// 	label: "Lease Term End Date",
	// 	db_field: "lease_end_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	{
		label: "Lock In",
		db_field: "lock_in",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Notice",
		db_field: "notice",
		required: true,
		datatype: "TEXT",
	},
	// {
	// 	label: "Term Renewal Date",
	// 	db_field: "term_renewal_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },

	// RENT

	{
		label: "Rent Schedule",
		db_field: "rent_schedule",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...RENT_SCHEDULE_OPTIONS],
		default_value: "SELECT",
	},

	// {
	// 	label: "Revenue Share (in %)",
	// 	db_field: "revenue_share",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },

	//BILLING ADDRESS

	{
		label: "Billing Unit No",
		db_field: "billing_unit",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Billing City",
		db_field: "billing_city",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Billing State",
		db_field: "billing_state",
		required: true,
		datatype: "TEXT",
		disable: true,
	},
	{
		label: "Billing Pincode",
		db_field: "billing_pincode",
		required: true,
		datatype: "NUMBER",
		custom: true,
		params: "billing_state",
	},
	{
		label: "Billing Address",
		db_field: "billing_address",
		required: true,
		datatype: "TEXT",
	},

	// Stock delivery address
	// {
	// 	label: "Stock Delivery Unit No",
	// 	db_field: "billing_unit_no",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Stock Delivery City",
	// 	db_field: "stock_delivery_city",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Stock Delivery State",
	// 	db_field: "stock_delivery_state",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Stock Delivery Pincode",
	// 	db_field: "stock_delivery_pincode",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Stock Delivery Address",
	// 	db_field: "stock_delivery_address",
	// 	required: false,
	// 	datatype: "TEXT",
	// },

	// Misc

	// {
	// 	label: "Marketing Charges / Month",
	// 	db_field: "marketing_charges_per_month",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Gift Vouchers / Month",
	// 	db_field: "gift_voucher_per_month",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Actual CAPEX",
	// 	db_field: "actual_capex",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Projected CAPEX",
	// 	db_field: "projected_capex",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "CAPEX Sale Value",
	// 	db_field: "capex_sale_value",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Deposit Against Goods",
	// 	db_field: "deposit_against_goods",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "BR Number",
	// 	db_field: "br_number",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Board Resolution Date",
	// 	db_field: "board_resolution_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	// {
	// 	label: "Authorized Signatory",
	// 	db_field: "authorized_signatory",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	// {
	// 	label: "Stamp Duty And Registration",
	// 	db_field: "stamp_duty_and_registration",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Insurance Amount",
	// 	db_field: "insurance_amount",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Insurance End Date",
	// 	db_field: "insurance_end_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	// {
	// 	label: "Agreement Expitry Date",
	// 	db_field: "agreement_expiry_date",
	// 	required: false,
	// 	datatype: "DATE",
	// },
	// {
	// 	label: "CAPEX Insurance",
	// 	db_field: "capex_insurance",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	// {
	// 	label: "Sellable Insurance",
	// 	db_field: "sellable_insurance",
	// 	required: false,
	// 	datatype: "NUMBER",
	// },
	{
		label: "GSTIN",
		db_field: "gstin",
		required: true,
		datatype: "TEXT",
	},
	// {
	// 	label: "Agreement Registered",
	// 	db_field: "agreement_registered",
	// 	required: false,
	// 	datatype: "TEXT",
	// 	type: "SELECT",
	// 	options: [...AGREEMENT_REGISTERED_OPTIONS],
	// 	default_value: "SELECT",
	// },
	{
		label: "GST Registered",
		db_field: "gst_registered",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...GST_REGISTERED_OPTIONS],
		default_value: "SELECT",
	},
	// {
	// 	label: "Shop & Est Date",
	// 	db_field: "shop_and_est_date",
	// 	required: false,
	// 	datatype: "Date",
	// },
	// {
	// 	label: "Shop & Est Number",
	// 	db_field: "shop_est_number",
	// 	required: false,
	// 	datatype: "TEXT",
	// },
	{
		label: "Is Active",
		db_field: "is_active",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...IS_ACTIVE_OPTIONS],
		default_value: "SELECT",
	},
];

export const all_fields = [
	{
		label: "RR/RSM/THOR",
		db_field: "rr_code",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...RR_RSM_THOR],
		default_value: "SELECT",
	},
	{
		label: "RR Code",
		db_field: "rr_number",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Brand",
		db_field: "brand",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...BRAND_OPTIONS],
		default_value: "SELECT",
		disable: true,
	},
	{
		label: "Reporting Name",
		db_field: "store_name",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Store Type",
		db_field: "store_type",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...STORE_TYPE_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Store Type As Per Acc",
		db_field: "store_type_as_per_acc",
		required: false,
		datatype: "TEXT",
		type: "SELECT",
		options: [...STORE_TYPE_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Name Of Franchise / Company",
		db_field: "name_of_franchise_company",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Unit No",
		db_field: "unit_no",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Floor",
		db_field: "floor",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Address",
		db_field: "address",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "State",
		db_field: "state",
		required: true,
		datatype: "TEXT",
		disable: true,
	},
	{
		label: "City",
		db_field: "city",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Tier",
		db_field: "tier",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...TIER_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Pincode",
		db_field: "pincode",
		required: true,
		datatype: "NUMBER",
		custom: true,
		params: "state",
	},
	{
		label: "Billing Name As Per Agreement",
		db_field: "billing_name_as_per_agreement",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Billing Name As Per Account",
		db_field: "billing_name_as_per_acc",
		required: false,
		datatype: "TEXT",
	},

	{
		label: "Mall / HS",
		db_field: "mall_hs",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...MALL_HS_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Zone",
		db_field: "zone",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...ZONE_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Carpet Area",
		db_field: "carpet_area",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Chargable Area",
		db_field: "chargable_area",
		required: true,
		datatype: "",
	},
	{
		label: "Facade In Ft",
		db_field: "facade_in_ft",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Security Deposit As Per Agreement",
		db_field: "security_deposit_as_per_agreement",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Security Deposit As Per Account",
		db_field: "security_deposit_as_per_account",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "CAM Deposit As Per Agreement",
		db_field: "cam_deposit_as_per_agreement",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "CAM Deposit As Per Account",
		db_field: "cam_deposit_as_per_account",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Store Opening Date",
		db_field: "store_opening_date",
		required: false,
		datatype: "DATE",
	},

	{
		label: "Rental Start Date",
		db_field: "rental_start_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Financial Year",
		db_field: "financial_year",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Electricity Deposit As Per Account",
		db_field: "electricity_deposit_as_per_acc",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Electricity Deposit As Per Agreement",
		db_field: "electricity_deposit_as_per_agreement",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Security Deposit As Per LOI",
		db_field: "security_deposit_as_per_loi",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "CAM Deposit As Per LOI",
		db_field: "cam_deposit_as_per_loi",
		required: true,
		datatype: "NUMBER",
	},

	// FITOUT
	{
		label: "Fitout Period",
		db_field: "fitout_period",
		required: true,
		datatype: "NUMBER",
	},
	{
		label: "Fitout Start Date",
		db_field: "fitout_start_date",
		required: true,
		datatype: "DATE",
	},

	{
		label: "Fitout End Date",
		db_field: "fitout_end_date",
		required: true,
		datatype: "DATE",
	},

	// TERM
	{
		label: "Lease Term",
		db_field: "lease_term",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Lease Term Start Date",
		db_field: "lease_start_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Lease Term End Date",
		db_field: "lease_end_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Lock In",
		db_field: "lock_in",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Notice",
		db_field: "notice",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Term Renewal Date",
		db_field: "term_renewal_date",
		required: false,
		datatype: "DATE",
	},

	// RENT

	{
		label: "Rent Schedule",
		db_field: "rent_schedule",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...RENT_SCHEDULE_OPTIONS],
		default_value: "SELECT",
	},

	{
		label: "Revenue Share (in %)",
		db_field: "revenue_share",
		required: false,
		datatype: "NUMBER",
	},

	//BILLING ADDRESS

	{
		label: "Billing Unit No",
		db_field: "billing_unit",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Billing City",
		db_field: "billing_city",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Billing State",
		db_field: "billing_state",
		required: true,
		datatype: "TEXT",
		disable: true,
	},
	{
		label: "Billing Pincode",
		db_field: "billing_pincode",
		required: true,
		datatype: "NUMBER",
		custom: true,
		params: "billng_state",
	},
	{
		label: "Billing Address",
		db_field: "billing_address",
		required: true,
		datatype: "TEXT",
	},

	// Stock delivery address
	{
		label: "Stock Delivery Unit No",
		db_field: "stock_delivery_unit",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Stock Delivery City",
		db_field: "stock_delivery_city",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Stock Delivery State",
		db_field: "stock_delivery_state",
		required: false,
		datatype: "TEXT",
		disable: true,
	},
	{
		label: "Stock Delivery Pincode",
		db_field: "stock_delivery_pincode",
		required: false,
		datatype: "NUMBER",
		custom: true,
		params: "stock_delivery_state",
	},
	{
		label: "Stock Delivery Address",
		db_field: "stock_delivery_address",
		required: false,
		datatype: "TEXT",
	},

	// Misc

	{
		label: "Marketing Charges / Month",
		db_field: "marketing_charges_per_month",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Gift Vouchers / Month",
		db_field: "gift_vouchers_per_month",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Actual CAPEX",
		db_field: "actual_capex",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Projected CAPEX",
		db_field: "projected_capex",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "CAPEX Sale Value",
		db_field: "capex_sale_value",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Deposit Against Goods",
		db_field: "deposit_against_goods",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "BR Number",
		db_field: "board_resolution_number",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Board Resolution Date",
		db_field: "board_resolution_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Authorized Signatory",
		db_field: "authorized_signatory",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Stamp Duty And Registration",
		db_field: "stamp_duty_and_registration",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Insurance Amount",
		db_field: "insurance_amount",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Insurance End Date",
		db_field: "insurance_end_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Agreement Expitry Date",
		db_field: "agreement_expiry_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "CAPEX Insurance",
		db_field: "capex_insurance",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "Sellable Insurance",
		db_field: "sellable_insurance",
		required: false,
		datatype: "NUMBER",
	},
	{
		label: "GSTIN",
		db_field: "gstin",
		required: true,
		datatype: "TEXT",
	},
	{
		label: "Agreement Registered",
		db_field: "agreement_registered",
		required: false,
		datatype: "TEXT",
		type: "SELECT",
		options: [...AGREEMENT_REGISTERED_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "GST Registered",
		db_field: "gst_registered",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...GST_REGISTERED_OPTIONS],
		default_value: "SELECT",
	},
	{
		label: "Shop & Est Date",
		db_field: "shop_est_date",
		required: false,
		datatype: "DATE",
	},
	{
		label: "Shop & Est Number",
		db_field: "shop_est_liscense_no",
		required: false,
		datatype: "TEXT",
	},
	{
		label: "Is Active",
		db_field: "is_active",
		required: true,
		datatype: "TEXT",
		type: "SELECT",
		options: [...IS_ACTIVE_OPTIONS],
		default_value: "SELECT",
	},
];

export const UPDATE_INITIAL_STATE = {
	store_type: "SELECT",
	rr_code: "SELECT",
	rr_number: "",
	brand: "SELECT",
	store_name: "",
	// ginesys_name: "",
	store_type_as_per_acc: "SELECT",
	name_of_franchise_company: "",
	unit_no: "",
	floor: 0,
	address: "",
	state: "",
	city: "",
	tier: "SELECT",
	pincode: 0,
	billing_name_as_per_agreement: "",
	billing_name_as_per_acc: "",
	mall_hs: "SELECT",
	zone: "SELECT",
	carpet_area: 0,
	chargable_area: 0,
	facade_in_ft: 0,
	deposit_paid_to_party: 0,
	security_deposit_as_per_agreement: 0,
	cam_deposit_as_per_agreement: 0,
	security_deposit_as_per_account: 0,
	cam_deposit_as_per_account: 0,
	store_opening_date: new Date().toJSON().slice(0, 10),
	rental_start_date: new Date().toJSON().slice(0, 10),
	financial_year: "",
	lease_term: "",
	start_date: new Date().toJSON().slice(0, 10),
	end_date: new Date().toJSON().slice(0, 10),
	lock_in: "",
	notice: "",
	rent_renewal_date: new Date().toJSON().slice(0, 10),
	revenue_share: "",
	billing_unit: "",
	billing_city: "",
	billing_state: "",
	billing_pincode: 0,
	billing_address: "",
	stock_delivery_unit: "",
	stock_delivery_city: "",
	stock_delivery_state: "",
	stock_delivery_pincode: 0,
	stock_delivery_address: "",
	marketing_charges_per_month: "",
	gift_vouchers_per_month: "",
	deposit_against_goods: 0,
	electricity_deposit_as_per_acc: 0,
	electricity_deposit_as_per_agreement: 0,
	board_resolution_number: "",
	board_resolution_date: new Date().toJSON().slice(0, 10),
	authorized_signatory: "",
	stamp_duty_and_registration: 0,
	gstin: "",
	agreement_registered: "SELECT",
	gst_registered: "SELECT",
	is_active: "SELECT",
	insurance_amount: 0,
	insurance_end_date: new Date().toJSON().slice(0, 10),
	agreement_expiry_date: new Date().toJSON().slice(0, 10),
	capex_insurance: 0,
	sellable_insurance: 0,
	fitout_period: 0,
	fitout_start_date: new Date().toJSON().slice(0, 10),
	fitout_end_date: new Date().toJSON().slice(0, 10),
	actual_capex: 0,
	projected_capex: 0,
	capex_sale_value: 0,
	site_code: "",
	mapping_code: "",
	rent_schedule: "SELECT",
	shop_est_date: new Date().toJSON().slice(0, 10),
	shop_est_liscense_no: "",
};

export const CREATE_INITIAL_STATE = {
	store_type: "SELECT",
	rr_code: "SELECT",
	rr_number: "",
	brand: "SELECT",
	store_name: "",
	// ginesys_name: "",
	// store_type_as_per_acc: "SELECT",
	name_of_franchise_company: "",
	unit_no: "",
	floor: 0,
	address: "",
	state: "",
	city: "",
	tier: "SELECT",
	pincode: 0,
	// billing_name_as_per_agreement: "",
	// billing_name_as_per_acc: "",
	mall_hs: "SELECT",
	zone: "SELECT",
	carpet_area: 0,
	chargable_area: 0,
	// facade_in_ft: 0,
	// deposit_paid_to_party: 0,
	// security_deposit_as_per_agreement: 0,
	// cam_deposit_as_per_agreement: 0,
	// security_deposit_as_per_account: 0,
	// cam_deposit_as_per_account: 0,
	// store_opening_date: new Date().toJSON().slice(0, 10),
	// rental_start_date: new Date().toJSON().slice(0, 10),
	// financial_year: "",
	lease_term: "",
	// start_date: new Date().toJSON().slice(0, 10),
	// end_date: new Date().toJSON().slice(0, 10),
	lock_in: "",
	notice: "",
	// rent_renewal_date: new Date().toJSON().slice(0, 10),
	// revenue_share: "",
	billing_unit: "",
	billing_city: "",
	billing_state: "",
	billing_pincode: 0,
	billing_address: "",
	// stock_delivery_unit: "",
	// stock_delivery_city: "",
	// stock_delivery_state: "",
	// stock_delivery_pincode: 0,
	// stock_delivery_address: "",
	// marketing_charges_per_month: "",
	// gift_vouchers_per_month: "",
	// deposit_against_goods: 0,
	electricity_deposit_as_per_acc: 0,
	electricity_deposit_as_per_agreement: 0,
	// board_resolution_number: "",
	// board_resolution_date: new Date().toJSON().slice(0, 10),
	// authorized_signatory: "",
	// stamp_duty_and_registration: 0,
	gstin: "",
	// agreement_registered: "SELECT",
	gst_registered: "SELECT",
	is_active: "SELECT",
	// insurance_amount: 0,
	// insurance_end_date: new Date().toJSON().slice(0, 10),
	// agreement_expiry_date: new Date().toJSON().slice(0, 10),
	// capex_insurance: 0,
	// sellable_insurance: 0,
	fitout_period: 0,
	fitout_start_date: new Date().toJSON().slice(0, 10),
	fitout_end_date: new Date().toJSON().slice(0, 10),
	// actual_capex: 0,
	// projected_capex: 0,
	// capex_sale_value: 0,
	// site_code: "",
	// mapping_code: "",
	rent_schedule: "SELECT",
	security_deposit_as_per_loi: 0,
	cam_deposit_as_per_loi: 0,
	// shop_est_date: new Date().toJSON().slice(0, 10),
	// shop_est_liscense_no: "",
};
