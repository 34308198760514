import { Button, CircularProgress, Dialog, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CircularProgressWithLabel from "components/ReusableComponents/CircularProgressWithLabel";
import { green, grey, red, yellow } from "@mui/material/colors";
import axios from "axios";
import { BusinessPlanState } from "context/BusinessPlanningContext";
import { roundDecimalValueUptoTwo } from "utils/roundDecimalValue";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "context/GlobalContext";
import { getDatesofSeason } from "utils/getDatesofSeason";
import { calculateAsp, calculateDiscount, calculateRos } from "utils/calculateStoreData";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";
import { rootProtectedPath, services } from "data/authData";

const dummy = {
	// mrp: 2499,
	prev_qty: 500,
	new_qty: null,
	prev_dis: 22,
	new_dis: null,
	prev_st: 76,
	new_st: null,
	prev_contri: 2,
	new_contri: 0,
	total_pieces: "",
	prev_asp: ".",
	new_asp: ".",
	prev_nsv: ".",
	new_nsv: ".",
	net_sales: ".",
};
const borderLeft = `0px`;
const borderRight = `2px solid #000`;
const borderTop = `2px solid #000`;
const borderBottom = `2px solid #000`;
const leftTopBorderRadius = `8px 0px 0px 0px`;
const rightTopBorderRadius = `0px 8px 0px 0px`;
const leftBottomBorderRadius = `4px 0px 0px 4px`;
const rightBottomBorderRadius = `4px 0px 4px 0px`;

const largeFont = 15;
const normalFont = 14;

const defColumn = {
	// padding: "0px 4px",
	height: "32px",
	text: { fontSize: "14px", fontWeight: 600 },
};
const DefRenderCellClass = {
	borderBottom: "none",
	width: "100%",
	textAlign: "center",
	cursor: "pointer",
	minWidth: "100%",
	height: "100%",
	borderBottom: `2px solid ${grey[300]}`,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

const DefRenderCell = ({ value, row, font }) => {
	return (
		<Box
			sx={{
				...DefRenderCellClass,
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				fontSize: font,
			}}>
			{value}
		</Box>
	);
};

const TableInLakhsDisplay = ({ value, row, font }) => {
	return (
		<Box
			sx={{
				...DefRenderCellClass,
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				fontSize: font,
			}}>
			<InLakhsDisplay number={value} sx={{ fontWeight: "normal" }} />
		</Box>
	);
};

const QtyWithContribution = ({ value, row, border, type, total_qty }) => {
	let borderToAdd = {};
	const contri = roundDecimalValueUptoTwo((total_qty ? value / total_qty : 0) * 100);
	if (border) borderToAdd = { ...border };
	if (row.DEPARTMENT === "TOTAL") borderToAdd = { ...borderToAdd, borderColor: grey[200], borderWidth: "0px" };
	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				borderBottom: `2px solid ${grey[300]}`,
				cursor: "pointer",
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				height: "100%",
				display: "flex",
				justifyContent: "space-around",
				alignItems: "center",
				...borderToAdd,
			}}>
			<Typography
				fontSize={type === "NEW" ? normalFont : largeFont}
				fontWeight={type === "NEW" ? "normal" : "bold"}>
				{value ? value : 0}
			</Typography>
			<Typography
				fontSize={type === "NEW" ? normalFont - 2 : largeFont - 3}
				fontWeight={type === "NEW" ? "normal" : "bold"}>
				<span
					style={{
						color: row.DEPARTMENT === "TOTAL" ? red[400] : type === "NEW" ? green[400] : red["A400"],
					}}>
					{contri}%
				</span>
			</Typography>
		</Box>
	);
};

const DiscountRenderCell = ({ row, type, value }) => {
	let borderToAdd = {};
	if (row.DEPARTMENT === "TOTAL") borderToAdd = { ...borderToAdd, borderColor: grey[200], borderWidth: "0px" };
	return (
		<Box
			sx={{
				cursor: "pointer",
				width: "100%",
				height: "100%",
				textAlign: "center",
				fontWeight: row.DEPARTMENT === "TOTAL" ? "bold" : "normal",
				backgroundColor: row.DEPARTMENT === "TOTAL" ? "#000" : "inherit",
				color: row.DEPARTMENT === "TOTAL" ? "white" : grey[800],
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontWeight: type === "NEW" ? "normal" : "bold",
				fontSize: type === "NEW" ? normalFont : largeFont,
				...borderToAdd,
				borderBottom: `2px solid ${grey[300]}`,
			}}>
			{value ? <>{roundDecimalValueUptoTwo(value)}</> : <>0</>}
			<span style={{ fontWeight: "normal", fontSize: "14px" }}>%</span>
		</Box>
	);
};

const CategoryTable = ({ division }) => {
	const { selectedBp, selectedStore, setSelectedStore, selectedCategory, setSelectedCategory } = BusinessPlanState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [allMrps, setAllMrps] = useState([
		{
			category: "Shirt",
			subCategory: "Printed",
			allMrps: [
				{
					id: 10,
					category: "Total",
					sub_category: "--",
					sleeve_len: "--",
					mrp: 34999,
					prev_qty: 6000,
					new_qty: 8000,
					prev_dis: 22,
					new_dis: 18,
					prev_st: 70,
					new_st: 80,
					total_pieces: 600,
					prev_asp: 2000,
					new_asp: 3000,
					prev_nsv: 1000000,
					new_nsv: 2000000,
					net_sales: 900000,
				},
				{
					id: 0,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "LS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 1,
					category: "Shirt",
					sleeve_len: "LS",
					sub_category: "Printed",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 2,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "LS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 3,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 4,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 5,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 6,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 7,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 8,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 9,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 10,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 11,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 12,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 13,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 14,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 15,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 16,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 17,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 18,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 19,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 20,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 21,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 1999,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 22,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 2599,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
				{
					id: 23,
					category: "Shirt",
					sub_category: "Printed",
					sleeve_len: "SS",
					mrp: 3499,
					prev_qty: 500,
					new_qty: 0,
					prev_dis: 22,
					new_dis: 0,
					prev_st: 76,
					new_st: 0,
					total_pieces: 0,
					prev_asp: 0,
					new_asp: 0,
					prev_nsv: 0,
					new_nsv: 0,
					net_sales: 0,
				},
			],
		},
	]);
	useEffect(() => {
		let total_qty = 0;
		let filterArr = [];
		if (selectedStore.storeCategories) {
			selectedStore.storeCategories.forEach((row) => {
				if (row.division === division) {
					total_qty += row.ltl_qty;
					filterArr.push(row);
				}
			});
			setNewAllMrps(filterArr);
			setLTLCount(total_qty);
			setNewCount(selectedStore.total_qty);
		}
	}, [selectedStore, division]);
	const [newAllMrps, setNewAllMrps] = useState([]);
	const [LTLCount, setLTLCount] = useState(0);
	const [newCount, setNewCount] = useState(0);
	const [loadingDailogue, setLoadingDailogue] = useState(false);
	const navigate = useNavigate();
	// useEffect(() => {
	// 	const fetch = async () => {
	// 		await axios
	// 			.get(`/api/item_master/get_dep_subCat_by_brand_division?brand=RARE RABBIT&division=APPARELS`)
	// 			.then((res) => {
	// 				const arr = [];
	// 				arr.push({
	// 					id: "9000wxc",
	// 					DEPARTMENT: "TOTAL",
	// 					sub_category: "--",
	// 					sleeve_len: "--",
	// 					mrp: 600000,
	// 					prev_qty: 6000,
	// 					new_qty: null,
	// 					new_dis: null,
	// 					prev_dis: 22,
	// 					prev_st: 76,
	// 					new_st: 0,
	// 					prev_contri: 100,
	// 					new_contri: "0",
	// 					total_pieces: 600,
	// 					prev_asp: 2000,
	// 					new_asp: 3000,
	// 					prev_nsv: 1000000,
	// 					new_nsv: 2000000,
	// 					net_sales: 900000,
	// 				});
	// 				res.data.forEach((row) => {
	// 					arr.push({ ...dummy, ...row, id: row.DEPARTMENT + "-" + row.SUBCATE_DES3 + "1999", mrp: 1999 });
	// 					arr.push({
	// 						...dummy,
	// 						...row,
	// 						id: row.DEPARTMENT + "-" + row.SUBCATE_DES3 + "2500",
	// 						mrp: 2500,
	// 					});
	// 					arr.push({
	// 						...row,
	// 						...dummy,
	// 						id: row.DEPARTMENT + "-" + row.SUBCATE_DES3 + "3500",
	// 						mrp: 3499,
	// 					});
	// 				});

	// 				setNewAllMrps(arr);
	// 				console.log(res.data);
	// 			});
	// 	};
	// 	fetch();
	// }, []);
	const tableColumns = [
		{
			field: "category",
			width: 130,
			headerAlign: "center",
			headerName: "Category",
			colSpan: ( val,row) => {
				if (row?.DEPARTMENT === "TOTAL") {
					return 3;
				}
				return undefined;
			},
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.category} row={row} font={normalFont} />;
			},
		},

		{
			field: "ltl_qty",
			headerName: "PRV QTY",
			width: 120,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <QtyWithContribution value={row.ltl_qty} row={row} type="OLD" total_qty={LTLCount} />;
			},
		},
		{
			field: "new_qty",
			headerName: "NEW QTY",
			width: 120,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <QtyWithContribution value={row.new_qty} row={row} type="NEW" total_qty={newCount} />;
			},
		},
		{
			field: "ltl_dis",
			headerName: "PRV DIS",
			headerAlign: "center",
			width: 80,
			renderCell: (params) => {
				return <DiscountRenderCell type="OLD" row={params.row} value={params.row.ltl_dis} />;
			},
		},
		{
			field: "new_dis",
			headerName: "NEW DIS",
			headerAlign: "center",
			width: 90,
			renderCell: (params) => {
				return <DiscountRenderCell type="NEW" value={params.row.new_dis} row={params.row} />;
			},
		},
		{
			field: "ltl_st",
			headerName: "PRV ST",
			headerAlign: "center",

			width: 60,
			renderCell: (params) => {
				return <DiscountRenderCell type="OLD" value={params.row.ltl_st} row={params.row} />;
			},
		},
		{
			field: "new_st",
			headerName: "NEW ST",
			width: 95,
			headerAlign: "center",

			renderCell: (params) => {
				return <DiscountRenderCell type="NEW" value={params.row.new_st} row={params.row} />;
			},
		},

		{
			field: "total_pcs",
			headerName: "TOTAL PCS",
			width: 85,
			headerAlign: "center",
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.total_pcs} row={row} border={{ borderLeft }} />;
			},
		},
		{
			field: "ltl_asp",
			headerName: "PRV ASP",
			width: 90,
			headerAlign: "center",
			renderCell: ({ row }) => {
				return <DefRenderCell value={row.ltl_asp} row={row} />;
			},
		},
		{
			field: "new_asp",
			headerName: "NEW ASP",
			width: 95,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <DefRenderCell value={row.new_asp} row={row} />;
			},
		},
		{
			field: "ltl_nsv",
			headerName: "PRV NSV",
			width: 100,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <TableInLakhsDisplay value={row.ltl_nsv} row={row} />;
			},
		},
		{
			field: "new_nsv",
			headerName: "NEW NSV",
			width: 110,
			headerAlign: "center",

			renderCell: ({ row }) => {
				return <TableInLakhsDisplay value={row.new_nsv} row={row} />;
			},
		},
	];
	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const autoCreateMrpTableRows = async (subCategory, allMrps) => {
		const mrps = allMrps?.filter((row) => row.SUBCATEGORY === subCategory);

		const attributeTypes = [
			...new Set([
				...mrps.reduce((acc, row) => {
					return [...acc, row.ATTRIBUTE_VALUE];
				}, []),
			]),
		];
		console.log(attributeTypes, mrps);
		let finalMrps = attributeTypes.reduce((allMrp, attribute) => {
			// console.log(allMrp, attribute, arr);
			let filteredMrp = [];
			let disabledMRPS = {};

			const mrpNumbers = mrps
				.filter((row) => row.ATTRIBUTE_VALUE === attribute)
				// .reduce((mrpArr, row) => {
				// 	mrpArr.push(row.MRP);
				// 	return mrpArr;
				// }, [])
				.sort((a, b) => a.MRP - b.MRP);
			let last = { MRP: 0 };
			console.log(mrpNumbers);
			mrpNumbers.forEach((mrp, i) => {
				if (mrpNumbers[i + 1] && mrpNumbers[i + 1].MRP - mrp.MRP > 200) {
					if (mrp.MRP - last.MRP <= 200) {
						const lowMrp = filteredMrp[filteredMrp.length - 1];
						disabledMRPS[last.MRP] = true;
						// filteredMrp.pop();
						// filteredMrp.push({
						// 	...mrp,
						// 	BILL_QUANTITY: mrp.BILL_QUANTITY + lowMrp.BILL_QUANTITY,
						// 	REALISED_VALUE: mrp.REALISED_VALUE + lowMrp.REALISED_VALUE,
						// 	INV_QTY: mrp.INV_QTY + lowMrp.INV_QTY,
						// 	ST: ((mrp.BILL_QUANTITY + lowMrp.BILL_QUANTITY) * 100) / (mrp.INV_QTY + lowMrp.INV_QTY),
						// });
					} else {
						filteredMrp.push(mrp);
						last = mrp;
					}
				} else if (mrp.MRP - last.MRP > 200) {
					// disabledMRPS[last.MRP] = false;
					filteredMrp.push(mrp);
					last = mrp;
				} else if (mrp.MRP - last.MRP <= 200) {
					const lowMrp = filteredMrp[filteredMrp.length - 1];
					disabledMRPS[last.MRP] = true;

					// filteredMrp.pop();
					// filteredMrp.push({
					// 	...mrp,
					// 	BILL_QUANTITY: mrp.BILL_QUANTITY + lowMrp.BILL_QUANTITY,
					// 	REALISED_VALUE: mrp.REALISED_VALUE + lowMrp.REALISED_VALUE,
					// 	INV_QTY: mrp.INV_QTY + lowMrp.INV_QTY,
					// 	ST: ((mrp.BILL_QUANTITY + lowMrp.BILL_QUANTITY) * 100) / (mrp.INV_QTY + lowMrp.INV_QTY),
					// 	TOTAL_MRP: mrp.TOTAL_MRP + lowMrp.TOTAL_MRP,
					// });
				} else {
					filteredMrp.push(mrp);
					last = mrp;
				}
			});
			console.log(mrpNumbers, filteredMrp);
			return [
				...allMrp,
				...mrpNumbers.map((row) => {
					return {
						...row,
						mrp: row.MRP,
						sub_category: row.SUBCATEGORY,
						ltl_qty: row.BILL_QUANTITY,
						ltl_nsv: row.REALISED_VALUE,
						ltl_dis: calculateDiscount(row.MRP * row.BILL_QUANTITY, row.REALISED_VALUE),
						ltl_st: row.ST,
						ltl_inventory_qty: row.INV_QTY,
						ltl_total_mrp: row.TOTAL_MRP,
						attribute_name: row.ATTRIBUTE_NAME,
						attribute_value: row.ATTRIBUTE_VALUE,
						disabled: disabledMRPS[row.MRP] === true ? true : false,
					};
				}),
			];
		}, []);

		return finalMrps;
	};
	const autoCreateSubCategoryTableRows = async (row) => {
		const { startDate, endDate } = getDatesofSeason(selectedBp.season, selectedBp.year);

		const dataPromise = new Promise(async (resolve, reject) => {
			await axios
				.get(`/api/item_master/get_sub_cat_by_brand_cat?brand=${selectedStore.brand}&category=${row.category}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					resolve("error");
				});
		});
		const historicalDataPromise = new Promise(async (resolve, reject) => {
			await axios
				.get(
					`/api/store/get_ebo_sub_cat_lev_season_data_by_store_with_dispatch?startDate=${startDate}&endDate=${endDate}&storeCode=${selectedStore.mapping_code}&category=${row.category}&brand=${selectedStore.brand}&season=${selectedBp.name}`
				)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					resolve("error");
				});
		});
		const allMrpDataPromise = new Promise(async (resolve, reject) => {
			await axios
				.get(
					`/api/store/get_mrp_by_sub_category?brand=${selectedStore.brand}&category=${row.category}&startDate=${startDate}&endDate=${endDate}&storeCode=${selectedStore.mapping_code}&season=${selectedBp.name}`
				)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					resolve("error");
				});
		});
		const [data, historicalData, allMrpData] = await Promise.all([
			dataPromise,
			historicalDataPromise,
			allMrpDataPromise,
		]);
		console.log(data, historicalData, allMrpData);
		if (data !== "error" && historicalData !== "error" && allMrpData !== "error") {
			const categorySubCategories = data.map((subCat, i) => {
				return {
					// id: i,
					category_lev_bp_id: row.id,
					sub_category: subCat.SUBCATE_DES3,
					category: subCat.DEPARTMENT,
				};
			});
			const map = {};
			historicalData.forEach((ele, i) => {
				map[ele.SUBCATEGORY] = i;
			});

			console.log(categorySubCategories, map, historicalData);

			const fianlCatSubCategories = [];
			for (let i = 0; i < categorySubCategories.length; i++) {
				let newObj = {};
				const subCategory = categorySubCategories[i];

				if (map[subCategory.sub_category] >= 0) {
					console.log(historicalData[map[subCategory.sub_category]]);
					const allMRP = await autoCreateMrpTableRows(subCategory.sub_category, allMrpData);
					// console.log(allMRP);
					let ltlData = historicalData[map[subCategory.sub_category]];

					newObj = {
						ltl_total_mrp: ltlData.TOTAL_MRP,
						ltl_nsv: ltlData.REALISED_VALUE,
						ltl_qty: ltlData.BILL_QUANTITY,
						ltl_asp: calculateAsp(ltlData.REALISED_VALUE, ltlData.BILL_QUANTITY),
						ltl_dis: calculateDiscount(ltlData.TOTAL_MRP, ltlData.REALISED_VALUE),
						ros: calculateRos(ltlData.BILL_QUANTITY),
						subCategoryMrps: allMRP,
						total_count: allMRP.length,
						ltl_st: ltlData.ST,
						ltl_inventory_qty: ltlData.INV_QTY,
					};
				}
				fianlCatSubCategories.push({
					...subCategory,
					...newObj,
				});
			}
			return fianlCatSubCategories;
		} else throw new Error("Unable to load data");
	};

	const handleGridRowClick = async (params, e) => {
		const row = params.row;
		// console.log(row);
		setLoadingDailogue(true);

		try {
			if (row.total_count === 0) {
				const categorySubCategories = await autoCreateSubCategoryTableRows(row);
				// console.log(categorySubCategories);
				const body = { categorySubCategories };
				setSelectedCategory({ ...row, categorySubCategories });
				await axios.post("/api/business_plan/bulk_create_sub_cat_lev_data", body).then((res) => {
					setSelectedCategory(res.data);
					setSelectedStore({
						...selectedStore,
						storeCategories: selectedStore.storeCategories.map((category) => {
							if (category.id === row.id) return res.data;
							else return category;
						}),
					});
				});
			} else {
				await axios.get(`/api/business_plan/get_cat_lev_data?id=${row.id}`).then((res) => {
					setSelectedCategory(res.data);
				});
			}
			handleScrollToTop();
			setLoadingDailogue(false);

			navigate(`/${rootProtectedPath}/${services.business_plan.path}/category?id=${row.id}`);
		} catch (error) {
			setSnackBarOpen(true);
			setLoadingDailogue(false);
			console.log(error);
			const response = error.response;
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
		}
	};

	return (
		<Stack sx={{ width: "100%" }}>
			{/* {allMrps.map((row) => ( */}

			<Box display="flex">
				{/* <Typography width="50px">{row.category}</Typography>
					<Typography width="50px">{row.subCategory}</Typography> */}
				<DataGrid
					rows={newAllMrps}
					columns={tableColumns}
					columnHeaderHeight={35}
					getRowClassName={(params) =>
						params.row.completed
							? params.row.total_count === 0
								? "yellow"
								: "green"
							: params.row.total_count === 0 && params.row.ltl_qty === 0
							? "yellow"
							: ""
					}
					sx={{
						// mb: 10,
						// background: grey[200],
						borderRadius: "8px",
						minHeight: 300,
						maxHeight: 480,
						"&.MuiDataGrid-root": {
							boxSizing: "none",
							boxShadow: `2px 2px 5px ${grey[200]}`,
						},
						// "& .MuiDataGrid-row": {
						// },
						"& .MuiDataGrid-row:first-child  ": {},
						"& .MuiDataGrid-cell": {
							padding: 0,
							borderBottom: "0px",
						},
						"& .MuiDataGrid-columnHeader": {
							padding: 0,
						},
						".MuiDataGrid-columnSeparator": {
							display: "none",
						},
						// "& .MuiDataGrid-cell:first-child ": {
						// 	padding: 0,
						// 	// background: grey[900],
						// },
					}}
					// getRowHeight={({ id, densityFactor }) => {
					// 	if (id === allMrps[0].allMrps[0].id) {
					// 		return 40 * densityFactor;
					// 	}

					// 	return null;
					// }}
					// rowSpacingType=""
					rowHeight={36}
					showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params, e) => handleGridRowClick(params, e)}
					hideFooter={true}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 30 },
						},
					}}
					disableRowSelectionOnClick
					pageSizeOptions={[30, 50]}
				/>
			</Box>

			<Dialog onClose={() => {}} open={loadingDailogue}>
				<Box width="200px" height="100px" display="flex" alignItems="center" justifyContent="center">
					<CircularProgress />
				</Box>
			</Dialog>
		</Stack>
	);
};

export default CategoryTable;
